import { defineNuxtPlugin } from '#app/nuxt'
import { LazyConsumerModal, LazyConsumerPage, LazyFreemiumModal, LazyStoryblokButtons, LazyStoryblokConsumerContactTabs, LazyStoryblokConsumerHeader, LazyStoryblokConsumerProduct, LazyStoryblokConsumerProductList, LazyStoryblokCountryPlaylists, LazyStoryblokDecoratedImage, LazyStoryblokFooterButton, LazyStoryblokHistoryLeaf, LazyStoryblokHistoryTree, LazyStoryblokHorizontalBlocks, LazyStoryblokImageGrid, LazyStoryblokLanderAppTree, LazyStoryblokLanderBusinessAd, LazyStoryblokLanderIntro, LazyStoryblokLanderProducts, LazyStoryblokLanderSearch, LazyStoryblokLanderTextImage, LazyStoryblokLink, LazyStoryblokMarketingBanner, LazyStoryblokMarketingBanners, LazyStoryblokMostSungSongs, LazyStoryblokPerson, LazyStoryblokPopularGenres, LazyStoryblokPopularPlaylists, LazyStoryblokPopularSongs, LazyStoryblokRecentAdditions, LazyStoryblokSongHistory, LazyStoryblokSongRequestModal, LazyStoryblokTeamGroupList, LazyStoryblokTeamItem, LazyStoryblokTopArtists, LazyStoryblokVenueBanner, LazyStoryblokVenueCityButtonGrid, LazyStoryblokVenueDiscover, LazyStoryblokVenueSearchbar, LazyStoryblokVenueSuggestion, LazyStoryblokVenuesNearMe, LazyStoryblokVenuesTop, LazyStoryblokVerticalItem, LazyStoryblokButton, LazyStoryblokCallout, LazyStoryblokDropdown, LazyStoryblokHeader, LazyStoryblokImage, LazyStoryblokQuote, LazyStoryblokRichText, LazyStoryblokRichTextWrapper, LazyStoryblokSpacer, LazyStoryblokTextAndImage, LazyStoryblokTitle, LazyStoryblokVideo, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["ConsumerModal", LazyConsumerModal],
["ConsumerPage", LazyConsumerPage],
["FreemiumModal", LazyFreemiumModal],
["StoryblokButtons", LazyStoryblokButtons],
["StoryblokConsumerContactTabs", LazyStoryblokConsumerContactTabs],
["StoryblokConsumerHeader", LazyStoryblokConsumerHeader],
["StoryblokConsumerProduct", LazyStoryblokConsumerProduct],
["StoryblokConsumerProductList", LazyStoryblokConsumerProductList],
["StoryblokCountryPlaylists", LazyStoryblokCountryPlaylists],
["StoryblokDecoratedImage", LazyStoryblokDecoratedImage],
["StoryblokFooterButton", LazyStoryblokFooterButton],
["StoryblokHistoryLeaf", LazyStoryblokHistoryLeaf],
["StoryblokHistoryTree", LazyStoryblokHistoryTree],
["StoryblokHorizontalBlocks", LazyStoryblokHorizontalBlocks],
["StoryblokImageGrid", LazyStoryblokImageGrid],
["StoryblokLanderAppTree", LazyStoryblokLanderAppTree],
["StoryblokLanderBusinessAd", LazyStoryblokLanderBusinessAd],
["StoryblokLanderIntro", LazyStoryblokLanderIntro],
["StoryblokLanderProducts", LazyStoryblokLanderProducts],
["StoryblokLanderSearch", LazyStoryblokLanderSearch],
["StoryblokLanderTextImage", LazyStoryblokLanderTextImage],
["StoryblokLink", LazyStoryblokLink],
["StoryblokMarketingBanner", LazyStoryblokMarketingBanner],
["StoryblokMarketingBanners", LazyStoryblokMarketingBanners],
["StoryblokMostSungSongs", LazyStoryblokMostSungSongs],
["StoryblokPerson", LazyStoryblokPerson],
["StoryblokPopularGenres", LazyStoryblokPopularGenres],
["StoryblokPopularPlaylists", LazyStoryblokPopularPlaylists],
["StoryblokPopularSongs", LazyStoryblokPopularSongs],
["StoryblokRecentAdditions", LazyStoryblokRecentAdditions],
["StoryblokSongHistory", LazyStoryblokSongHistory],
["StoryblokSongRequestModal", LazyStoryblokSongRequestModal],
["StoryblokTeamGroupList", LazyStoryblokTeamGroupList],
["StoryblokTeamItem", LazyStoryblokTeamItem],
["StoryblokTopArtists", LazyStoryblokTopArtists],
["StoryblokVenueBanner", LazyStoryblokVenueBanner],
["StoryblokVenueCityButtonGrid", LazyStoryblokVenueCityButtonGrid],
["StoryblokVenueDiscover", LazyStoryblokVenueDiscover],
["StoryblokVenueSearchbar", LazyStoryblokVenueSearchbar],
["StoryblokVenueSuggestion", LazyStoryblokVenueSuggestion],
["StoryblokVenuesNearMe", LazyStoryblokVenuesNearMe],
["StoryblokVenuesTop", LazyStoryblokVenuesTop],
["StoryblokVerticalItem", LazyStoryblokVerticalItem],
["StoryblokButton", LazyStoryblokButton],
["StoryblokCallout", LazyStoryblokCallout],
["StoryblokDropdown", LazyStoryblokDropdown],
["StoryblokHeader", LazyStoryblokHeader],
["StoryblokImage", LazyStoryblokImage],
["StoryblokQuote", LazyStoryblokQuote],
["StoryblokRichText", LazyStoryblokRichText],
["StoryblokRichTextWrapper", LazyStoryblokRichTextWrapper],
["StoryblokSpacer", LazyStoryblokSpacer],
["StoryblokTextAndImage", LazyStoryblokTextAndImage],
["StoryblokTitle", LazyStoryblokTitle],
["StoryblokVideo", LazyStoryblokVideo],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
